import { ApiCommunications, NullPromise } from '../../webmodule-common/interop/webmodule-interop';
import { RequestGetSupplierQuoteConfig, SupplierApi } from './supplier-api';

import {
  RequestGetSupplierPricingRule,
  RequestGetSupplierTAC,
  ResultGetSupplierPricingRule,
  ResultGetSupplierQuoteSettings,
  ResultGetSupplierTAC
} from './dealer-api-interface-franchisee';

export class SupplierApiImplementation implements SupplierApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async getSupplierQuoteConfig(request: RequestGetSupplierQuoteConfig): NullPromise<ResultGetSupplierQuoteSettings> {
    return await this.api.post<ResultGetSupplierQuoteSettings>(`api/SupplierNetwork/GetSupplierQuoteSettings`, request);
  }

  // This needs to be updated once we have a supplier portal. For now, we just add the markdown file in the
  // dealer blob container and read the information from there.
  async getSupplierTAC(request: RequestGetSupplierTAC): NullPromise<ResultGetSupplierTAC> {
    return await this.api.post<ResultGetSupplierTAC>(`api/SupplierNetwork/GetSupplierTAC`, request);
  }

  async getSupplierPricingRule(body: RequestGetSupplierPricingRule): NullPromise<ResultGetSupplierPricingRule> {
    return await this.api.post<ResultGetSupplierPricingRule>('api/SupplierNetwork/GetSupplierPricingRule', body);
  }
}
