// eslint-disable-next-line import/named
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { FormInputSelectValue } from '../../FormInputView';
import { html, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';

export function formRadioGroup(
  fieldName: string,
  title: string | undefined,
  options: string | FormInputSelectValue[],
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  readOnly = false,
  required = false,
  classes = ''
): TemplateResult {
  const internalOptions = options ?? [];

  const optionsArray: FormInputSelectValue[] =
    typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;

  const _options: { text: string; value: string; selected: boolean; disabled?: boolean }[] = [];

  optionsArray.forEach(x =>
    _options.push({
      text: x.text,
      value: x.value,
      selected: dataTracker.getObjectValue(fieldName) == x.value,
      disabled: readOnly || x.disabled
    })
  );

  const opts = html`${repeat(
    _options,
    x => x.value,
    (x, _index) => html` <webmodule-radio value="${x.value}" ?disabled="${x.disabled}">${x.text}</webmodule-radio>`
  )}`;

  return html` <webmodule-radio-group
    class="${classes} webmodule-control-left-label"
    id=${dataBinding.field(fieldName)}
    name=${dataBinding.field(fieldName)}
    label="${ifDefined(title)}:"
    ?required=${required}
    value=${dataTracker.getObjectValue(fieldName)}
    size="small"
    inline
  >
    ${opts}
  </webmodule-radio-group>`;
}
