import { awaitableTemplate } from '../../../../webmodule-common/other/ui/template-processor.js';
import { customElement } from 'lit/decorators.js';
import { DataEntryPageControlView } from '../../../../webmodule-common/other/ui/data-entry-screen-base.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid.js';
import { EventTemplate } from '../../../../webmodule-common/other/ui/events.js';
import { FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker.js';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant.js';
import { getApiFactory } from '../../../api/api-injector.js';
import { getQuoteSuppliers, isMultiSupplier, QuoteSupplier } from '../../../quotes/quote-service.js';
import { html } from 'lit-html';
import { isEmptyOrSpace, validId } from '../../../../webmodule-common/other/ui/string-helper-functions.js';
import { PaymentProfileCalculation } from '../../../api/dealer-api-interface-franchisee.js';
import {
  PaymentProfileDetailView,
  PaymentProfileDetailViewOptions
} from '../../../franchisee/view/payment-profile-detail-view.js';
import { PromiseTemplate, Snippet } from '../../../../webmodule-common/interop/types/misc.js';
import { tlang } from '../../../../webmodule-common/other/language/lang.js';
import {
  WebmoduleChangeEvent,
  WebmoduleRadioGroup
} from '../../../../webmodule-common/components/src/webmodule-components.js';
import { pricingModelType } from '../../../../webmodule-common/pricing/pricing-model';

@customElement('wm-franchiseepaymentprofiledetailview')
export class FranchiseePaymentProfileDetailView extends PaymentProfileDetailView {
  protected supplierApi = getApiFactory().supplier();
  protected suppliers: QuoteSupplier[] = [];

  constructor(owner: DataEntryPageControlView, options: PaymentProfileDetailViewOptions) {
    super(owner, options);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.paymentProfile),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('supplierId');
  }

  public async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    this.suppliers = await getQuoteSuppliers();
    if (!isMultiSupplier() && this.isNew()) {
      this.paymentProfile.supplierId = this.suppliers.length == 1 ? this.suppliers[0].supplierId : emptyGuid;
      this.paymentProfileManager.resetBackup();
    }
    this.requestUpdate();
  }

  public getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (isEmptyOrSpace(this.paymentProfile.supplierId)) {
      errors.push(tlang`Please Select a %%supplier%%.`);
    }

    return errors;
  }

  protected isNew(): boolean {
    return this.paymentProfile.id == emptyGuid;
  }

  protected getCaption(): Snippet {
    return tlang``;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    if (isMultiSupplier()) {
      this.suppliers.unshift({ description: '', supplierId: emptyGuid, online: false });
    }

    const supplierConverter = (qs: QuoteSupplier) => {
      return {
        text: tlang`${qs.description}`,
        value: qs.supplierId,
        disabled: false
      };
    };

    const isSupplierSelectorReadOnly = this.paymentProfile.supplierId != emptyGuid && !this.isNew();
    const supplierSelectorControl = isMultiSupplier()
      ? html` <bs-form-select
          data-id=${this.dataBinding.field('supplierId')}
          data-placeholder=${tlang`%%supplier%%`}
          data-label=${tlang`%%supplier%%`}
          ?readonly=${isSupplierSelectorReadOnly}
          ?disabled=${isSupplierSelectorReadOnly}
          .value=${this.paymentProfile.supplierId}
          .options=${this.suppliers.map(supplierConverter)}
          @wm-select-changed=${_ => this.onSupplierSelected(_.detail)}
        >
        </bs-form-select>`
      : forms.textHidden('supplierId');

    const marginTemplate = awaitableTemplate(this.getMarginTemplate(this.paymentProfile.supplierId, forms));

    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div @webmodule-change=${(e: WebmoduleChangeEvent) => this.updateCalculatedMethod(e)}>
            ${forms.textRequired('name', tlang`%%payment-profile%%`, 100)} ${supplierSelectorControl}
            ${forms.radioGroup('calculation', PaymentProfileCalculation, tlang`Calculation`)} ${marginTemplate}
            ${forms.note('quoteTerms', tlang`%%quote%% Terms and Conditions`, 5000)}
          </div>
        </div>
      </form>
    `;
  }

  protected async updateCalculatedMethod(e: WebmoduleChangeEvent): Promise<void> {
    const target = e.target;

    if (target instanceof WebmoduleRadioGroup) {
      this.pricingModel.update({ calculation: target.value as unknown as PaymentProfileCalculation });
      this.requestUpdate();
    }
  }

  protected async getMarginTemplate(supplierId: string, forms: FormInputAssistant): PromiseTemplate {
    this.pricingModel.update({
      min: null,
      max: null
    });
    if (validId(supplierId)) {
      const result = await this.supplierApi.getSupplierPricingRule({
        supplierId: supplierId
      });
      const isMargin = this.pricingModel.calculation === pricingModelType.margin;
      if (result) {
        const pricingRule = result.pricingRule;
        if (this.isNew()) {
          this.paymentProfile.value = pricingRule.minValue;
        }

        if (result.pricingRuleType.hasMinMax) {
          this.pricingModel.update({
            min: isMargin ? pricingRule.minValue : pricingRule.minMarkup,
            max: isMargin ? pricingRule.maxValue : pricingRule.maxMarkup
          });
        }
      }
    }

    return html`${forms.floatRequired(
      'value',
      `Value (%)`,
      this.pricingModel.min,
      this.pricingModel.max === -1 ? undefined : this.pricingModel.max
    )}`;
  }

  protected onSupplierSelected(supplierId: string) {
    this.paymentProfile.supplierId = supplierId;

    this.requestUpdate();
  }
}
