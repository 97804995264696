import { Address } from '../../interop/webmodule-interop';
import { bsFormRadioGroupYesNoOptions, displayYesNo, isEmptyOrSpace } from './string-helper-functions';
import { clone, compare } from '../clone';
import { customElement, property, state } from 'lit/decorators.js';
import { DataBinding } from './databinding/databinding';
import { DataTracker, FieldType } from './databinding/data-tracker';
import {
  emptyAddress,
  mapTemplate,
  singleLineAddress,
  updateAddress,
  updateAddressWithAddress
} from './maps/map-helpers';
import { FormInputAssistant } from './templateresult/form-input-assistant';
import { googleMapApiKey } from '../api/google-api';
import { html, LitElement } from 'lit';
import { map } from 'lit/directives/map.js';
import { newGuid } from '../api/guid';
import { Place } from './maps/place';
import { repeat } from 'lit/directives/repeat.js';
import { tlang } from '../language/lang';
import { tryParseFloat } from '../number-utilities';
import { WebmoduleChangeEvent, WebmoduleRadioGroup, WebmoduleSelect } from '../../components/src/webmodule-components';

export interface ShippingNotesContainer {
  shippingNotes?: string;
  backupShippingNotes?: string;
}

export interface AddressOption extends Address {
  id: string;
  display?: string;
}

@customElement('wm-addresseditor')
export class AddressEditor extends LitElement {
  @property({ type: Boolean })
  displayMap = true;
  @property({ type: Boolean })
  displayLookup = true;
  @property({ type: Boolean })
  isSameAsOtherAddressVisible = false;
  @property({ type: Object })
  subheader?: object;
  @property()
  eventTitle = 'Address';
  @property({ type: Boolean })
  readonly = false;
  @property({ type: Boolean })
  isDefaultShippingVisible = false;
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));
  private _backupAddress?: Address;
  private _notesContainer: ShippingNotesContainer = {};

  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.address),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    addField('line1', FieldType.string, false);
    addField('locality', FieldType.string, true);
    addField('region', FieldType.string, true);
    addField('postcode', FieldType.string, false);
    addField('country', FieldType.string, true);
    addField('shippingNotes', FieldType.string, true, undefined, () => this._notesContainer);
    this.dataTracker.addDynamic(
      'isDefaultShipping',
      FieldType.boolean,
      () => this.isDefaultShipping,
      (value: boolean) => {
        this.isDefaultShipping = value ?? false;
      }
    );
  }

  @state()
  private _isSameAsOtherAddress = false;

  public get isSameAsOtherAddress() {
    return this._isSameAsOtherAddress;
  }

  @property({ type: Boolean })
  public set isSameAsOtherAddress(value) {
    this._isSameAsOtherAddress = value;
    if (value) this._address = undefined;
    else if (!this._address) this._address = emptyAddress();
    this.addressChangedEvent();
  }

  @state()
  private _address?: Address | undefined;

  public get address(): Address | undefined {
    return this._address;
  }

  @property({ type: Object })
  public set address(value: Address | undefined) {
    this._address = clone(value);
    this._backupAddress = clone(value);
  }

  public get shippingNotes(): string | undefined {
    return this._notesContainer.shippingNotes;
  }

  @property()
  public set shippingNotes(value: string | undefined) {
    this._notesContainer.shippingNotes = value;
    this._notesContainer.backupShippingNotes = value;
  }

  @state()
  private _isDefaultShipping = false;

  public get isDefaultShipping() {
    return this._isDefaultShipping;
  }

  @property({ type: Boolean })
  public set isDefaultShipping(value) {
    this._isDefaultShipping = value;
    //this.addressChangedEvent();
  }

  private _preSelectAddressList?: AddressOption[];

  public get preSelectAddressList() {
    return this._preSelectAddressList;
  }

  @property({ type: Array })
  public set preSelectAddressList(value: AddressOption[] | undefined) {
    this._preSelectAddressList = value?.map(x => {
      return { ...x, id: x.id ?? newGuid() };
    });
  }

  private get addressDetailsVisible(): boolean {
    return (this.isSameAsOtherAddressVisible && !this.isSameAsOtherAddress) || !this.isSameAsOtherAddressVisible;
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    this.dispatchEvent(new CustomEvent(`wm-ae-${name}`, options));
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('webmodule-change', this.changeEvent);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('webmodule-change', this.changeEvent);
  }

  render() {
    const formsPhysical = new FormInputAssistant(this.dataTracker, this.readonly);

    const updateAddressEvent = _ => this.updateAddressFromMapHelper(_.detail.place);

    const defaultShippingTemplate = this.isDefaultShippingVisible
      ? formsPhysical.switch('isDefaultShipping', tlang`Default Shipping Address`, {
          readonly: this.isDefaultShippingReadonly()
        })
      : html``;

    const sameAsOtherChangeEvent = (e: WebmoduleChangeEvent) => {
      e.stopImmediatePropagation();
      const target = e.target as WebmoduleRadioGroup | null;

      if (!target) return;

      this.isSameAsOtherAddress = target.value === 'Yes';
    };
    const sameAsOtherAddressTemplate = this.isSameAsOtherAddressVisible
      ? html` <div>
          <webmodule-radio-group
            label=${tlang`Same as Physical Address`}
            id=${this.dataTracker.binder.field('sameAsOtherAddress')}
            value=${displayYesNo(this.isSameAsOtherAddress)}
            @webmodule-change=${sameAsOtherChangeEvent}
            size="small"
          >
            ${map(
              bsFormRadioGroupYesNoOptions(),
              x => html` <webmodule-radio value="${x.value}">${x.text}</webmodule-radio>`
            )}
          </webmodule-radio-group>
        </div>`
      : html``;

    const getAddressOptions = this._preSelectAddressList?.map(x => new Option(x.display ?? singleLineAddress(x), x.id));

    const lookupTemplate = this.displayLookup
      ? this.preSelectAddressList
        ? html`
            <webmodule-select
              class="webmodule-control-left-label"
              placeholder="${tlang`Select Address`}"
              label="${tlang`Lookup`}:"
              @webmodule-change="${(e: WebmoduleChangeEvent) => this.wmSelectChangedEvent(e)}"
              size="small"
              ?disabled="${this.readonly}"
            >
              ${repeat(
                getAddressOptions ?? [],
                x => x.id,
                x => html` <webmodule-option value=${x.value}>${x.text}</webmodule-option>`
              )}
            </webmodule-select>
          `
        : html`
            <google-place-autocomplete
              class="left right"
              data-id="clientauto"
              outline
              ?disabled=${this.readonly}
              searchType=${'address'}
              .apiKey=${googleMapApiKey}
              label=${tlang`Lookup:`}
              @place-changed=${updateAddressEvent}
            >
            </google-place-autocomplete>
          `
      : html``;
    const notesTemplate = html` <webmodule-textarea
      class="webmodule-control-left-label"
      id=${this.dataTracker.binder.field('shippingNotes')}
      value=${this.shippingNotes}
      label="${tlang`!!shipping-note!!`}:"
      ?readonly=${this.readonly}
      ?filled=${this.readonly}
      maxlength="500"
      size="small"
    ></webmodule-textarea>`;
    const mapViewTemplate = this.displayMap
      ? mapTemplate(tryParseFloat(this.address?.latitude), tryParseFloat(this.address?.longitude))
      : html``;
    const subheader = this.subheader ?? html``;
    const primaryDetailsTemplate = this.addressDetailsVisible
      ? html`
          <div class="row">
            <div>
              ${subheader} ${sameAsOtherAddressTemplate} ${lookupTemplate}
              ${formsPhysical.textRequired('line1', tlang`Street Address`, 120)}
              ${formsPhysical.text('locality', tlang`City`, 120)} ${formsPhysical.text('region', tlang`State`, 120)}
              ${formsPhysical.textRequired('postcode', tlang`Zip Code`, 20)}
              ${formsPhysical.text('country', tlang`Country`, 80)} ${notesTemplate} ${defaultShippingTemplate}
            </div>
            <div class="address-map">${mapViewTemplate}</div>
          </div>
        `
      : html`
          <div class="row">
            <div>${sameAsOtherAddressTemplate}</div>
            <div class=""></div>
          </div>
        `;

    return html`
      <div class="form-two-col">
        <h2>${this.eventTitle}</h2>
        ${primaryDetailsTemplate}
      </div>
    `;
  }

  updateAddressFromMapHelper(place: Place) {
    if (!this.address || !place) return;
    updateAddress(place, this.address);
    this.addressChangedEvent();
    //manually trigger as we modified the content of a property, but not the property itself.
    this.requestUpdate();
  }

  protected wmSelectChangedEvent(e: WebmoduleChangeEvent) {
    e.stopImmediatePropagation();
    const target = e.target as WebmoduleSelect;

    if (!target.value || !this._preSelectAddressList) {
      return;
    }

    const selectedAddress = this._preSelectAddressList.findIndex(x => x.id === target.value);

    if (selectedAddress < 0 || !this.address) {
      return;
    }

    updateAddressWithAddress(this.address, this._preSelectAddressList[selectedAddress]);
    this.addressChangedEvent();
    this.requestUpdate();
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected isDefaultShippingReadonly(): boolean {
    // Attempt to get the current value of the form during a render.
    // This will fail initially when loading so fall back to the project object.
    try {
      const line1 = this.dataTracker.binder.getValue('line1');
      const postcode = this.dataTracker.binder.getValue('postcode');
      return isEmptyOrSpace(line1) || isEmptyOrSpace(postcode);
    } catch {
      if (this.address) {
        return isEmptyOrSpace(this.address.line1) || isEmptyOrSpace(this.address.postcode);
      }
      return true;
    }
  }

  private addressChangedEvent() {
    this.dispatchCustom('changed', {
      address: this.address,
      shippingNotes: this.shippingNotes,
      isDefaultShipping: this.isDefaultShippingVisible ? this.isDefaultShipping : undefined,
      isSameAsOtherAddress: this.isSameAsOtherAddress
    });
  }

  private changeEvent = (e: WebmoduleChangeEvent) => {
    e.stopImmediatePropagation();
    if (this.addressDetailsVisible) this.dataTracker.applyChangeToValue();
    if (
      !compare(this._address, this._backupAddress) ||
      !compare(this._notesContainer.shippingNotes, this._notesContainer.backupShippingNotes)
    ) {
      this._backupAddress = clone(this._address);
      this._notesContainer.backupShippingNotes = this._notesContainer.shippingNotes;
      this.requestUpdate();
      this.addressChangedEvent();
    }
  };
}
