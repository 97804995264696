import { AskConfirmation } from './ui/modal-confirmation';
import { askOption } from './ui/modal-option';
import { asMarkdownTemplate } from './general/markdown';
import { EventBooleanAsync } from '../interop/webmodule-interop';
import { getCurrentUser } from './api/current-user';
import { getSaveIndicator } from './ui/save-notifier';
import { html, TemplateResult } from 'lit';
import { InformationDispatcher, InformationListener } from './ui/information-dispatcher';
import { init } from './app-global-init';
import { lang, tlang } from './language/lang';
import { LitBaseModal } from './ui/modal/modal-factory-lit';
import { sleep } from './general/time';
import { showValidationsWithAbandon } from './ui/modal-validationhandler';

let enableAutoSaveFeature = true;

export class ErrorAbandon extends Error {
  cause?: Error | undefined;

  constructor(name: string, message: string, stack?: string | undefined, cause?: Error | undefined) {
    super(message);
    this.name = name;
    this.stack = stack;
    this.cause = cause;
    Error.captureStackTrace(this, this.constructor);
  }
}

export class ErrorValidation extends Error {
  name: string;
  message: string;
  stack?: string | undefined;
  validations: string[];

  constructor(validations: string[]) {
    super(validations.join('\n'));
    this.name = 'ErrorValidation';
    this.validations = validations;
    Error.captureStackTrace(this, this.constructor);
  }
}

export function isAutoSaving(): boolean {
  return enableAutoSaveFeature;
}

export function setAutoSaveOnDealer(value: boolean) {
  enableAutoSaveFeature = value;
}

export async function saveWithIndicator(saveEvent: EventBooleanAsync, manualSave = false): Promise<boolean> {
  let saved = false;
  const autoSaving = !manualSave && isAutoSaving();
  const indicator = await getSaveIndicator(autoSaving);

  try {
    saved = await saveEvent();
  } catch (e) {
    saved = false;
    throw e;
  } finally {
    await indicator.completed(saved);
  }
  return saved;
}

init(() => {
  globalThis.dealerConfiguration.setAutoSave = setAutoSaveOnDealer;
});

export class SaveWorkflowModal extends LitBaseModal {
  information = '';
  info: InformationDispatcher;
  titleStr: string;
  minimumMilliSecondsOpen: number | undefined;
  private currentTime: number;
  private canCloseFlagged = false;

  constructor(title: string, info: InformationDispatcher, minSecondsOpen = 1.5) {
    super();
    this.currentTime = new Date().getTime();
    this.info = info;
    this.titleStr = title;
    this.minimumMilliSecondsOpen = minSecondsOpen ? minSecondsOpen * 1000 : undefined;
    this.information = info.information;
    this.info.addListener(this.event);
  }

  get isFooterVisible(): boolean {
    return false;
  }

  event: InformationListener = async (information: string) => {
    this.information = information;
    this.requestUpdate();
  };

  public done() {
    this.canCloseFlagged = true;
  }

  async canClose(): Promise<boolean> {
    return this.canCloseFlagged;
  }

  async hideModal(): Promise<void> {
    this.info.removeListener(this.event);
    const currentTicks = new Date().getTime() - this.currentTime;
    if (currentTicks > 100)
      if (this.minimumMilliSecondsOpen && currentTicks < this.minimumMilliSecondsOpen)
        //a fast life means no save
        await sleep(this.minimumMilliSecondsOpen - currentTicks);
    await super.hideModal();
  }

  title(): string | TemplateResult {
    return this.titleStr;
  }

  bodyTemplate(): TemplateResult {
    const saving = tlang`Processing...`;
    return html` <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="spinner-border text-success" style="width: 4rem; height: 4rem;" role="status">
        <span class="visually-hidden">${saving}</span>
      </div>
      <div class="alert alert-success col-12 mt-4 mb-0 pb-0" role="alert">
        <div class="col">${this.processedInformation()}</div>
      </div>
    </div>`;
  }

  processedInformation(): unknown {
    return asMarkdownTemplate(this.information);
  }
}

export interface SavePromptOptions {
  isReadonly: boolean;
  autoSaveEvent: (options: SavePromptOptions) => Promise<boolean>;
  needsSaveEvent: EventBooleanAsync;
  abandonSaveEvent: EventBooleanAsync;
  dictionaryName: string;
  displaySaveModal?: boolean;
  informationDispatcher?: InformationDispatcher;
}

export async function canClose(options: SavePromptOptions): Promise<boolean> {
  if (options.isReadonly || !getCurrentUser()) return true;

  const checkAbandon = async () =>
    await AskConfirmation(tlang`Save Failed`, {
      ok: tlang`Abandon Changes`,
      cancel: tlang`Stay On ${lang(options.dictionaryName)}`
    });

  const saveOrAbandon = async () => {
    let success = false;
    try {
      success = await options.autoSaveEvent(options);
      if (success) return true;
    } catch (e) {
      if (e instanceof ErrorValidation) {
        const abandon = await showValidationsWithAbandon(e.validations);
        if (abandon) await options.abandonSaveEvent();
        return abandon;
      }
      if (e instanceof ErrorAbandon) {
        options.abandonSaveEvent();
        return true;
      }
      throw e;
    }
    const abandon = await checkAbandon();
    if (abandon) options.abandonSaveEvent();
    return abandon;
  };

  const informationDispatcher = options.informationDispatcher ?? new InformationDispatcher();
  const saveModal = options.displaySaveModal
    ? new SaveWorkflowModal(tlang`Saving ${lang(options.dictionaryName)}`, informationDispatcher)
    : undefined;

  //we dont want to wait for the modal to finish.
  const modalState = await saveModal?.show();
  await modalState?.onShow;
  try {
    if (enableAutoSaveFeature) return await saveOrAbandon();
    else {
      if (await options.needsSaveEvent()) {
        const btnOptions = {
          save: 'save',
          close: 'close',
          edit: 'edit'
        };
        const option = await askOption(tlang`You have unsaved changes`, [
          {
            caption: tlang`Save`,
            value: btnOptions.save,
            btnClass: 'primary'
          },
          {
            caption: tlang`Abandon`,
            value: btnOptions.close,
            btnClass: 'danger'
          },
          {
            caption: tlang`Keep Editing`,
            value: btnOptions.edit,
            btnClass: 'default'
          }
        ]);
        if (option === btnOptions.save) {
          return await saveOrAbandon();
        } else if (option === btnOptions.close) return true;
        else return false;
      }
    }
    return true;
  } finally {
    saveModal?.done();
    await saveModal?.hideModal();
  }
}

async function handleAutoSaveEvent(options: SavePromptOptions): Promise<boolean> {
  try {
    const success = await options.autoSaveEvent(options);
    if (success) return true;
  } catch (e) {
    if (e instanceof ErrorValidation) {
      const abandon = await showValidationsWithAbandon(e.validations);
      if (abandon) await options.abandonSaveEvent();
      return abandon;
    }
    if (e instanceof ErrorAbandon) {
      await options.abandonSaveEvent();
      return true;
    }
    throw e;
  }
  return false;
}

export async function allowPageControlTabChange(options: SavePromptOptions): Promise<boolean> {
  if (options.isReadonly || !getCurrentUser()) return true;
  if (enableAutoSaveFeature) return await handleAutoSaveEvent(options);
  else {
    if (await options.needsSaveEvent()) {
      if (
        !(await AskConfirmation(tlang`You have unsaved changes`, {
          ok: tlang`Save Changes`,
          cancel: tlang`Stay On Page`
        }))
      )
        return false;
      else return await handleAutoSaveEvent(options);
    } else return true;
  }
  return false;
}
