// eslint-disable-next-line import/named
import {
  ConvertEnumToFormSelectOptions,
  FormInputSelectValue,
  MapArrayToFormInputSelectValue
} from '../../FormInputView';
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { formInput, InputEventMap, InputType } from './form-input';
import { formRadioGroup } from './form-radio-group';
import { formSelect } from './form-select';
import { html, nothing, TemplateResult } from 'lit';
import WebmoduleInput from '../../../components/src/components/input/input';
import { WebmoduleButtonSize, WebmoduleButtonVariant } from '../../../components/src/components/button/button';
import { isEmptyOrSpace } from '../string-helper-functions';

export interface InputClasses {
  id: string;
  classes: string;
}

export type ButtonSlot = 'suffix' | 'prefix';

export type EventPickerResult = (() => PickerResult | undefined) | (() => Promise<PickerResult | undefined>);

export interface PickerResult {
  id: string;
  value: string;
}

export interface ButtonOptions {
  events?: InputEventMap;
  readonly?: boolean;
  slot?: ButtonSlot;
  class?: string;
  size?: WebmoduleButtonSize;
}

interface ButtonOptionsInternal {
  events?: InputEventMap;
  readonly?: boolean;
  slot?: ButtonSlot;
  variant?: WebmoduleButtonVariant;
  size?: WebmoduleButtonSize;
  class?: string;
}

export class FormInputAssistant {
  dataTracker: DataTracker;
  classes: InputClasses[] = [];
  immediateBindingUpdate = false;

  constructor(
    dataTracker: DataTracker,
    forceReadonly: boolean | (() => boolean) = false,
    immediateBindingUpdate = false
  ) {
    this.dataTracker = dataTracker;
    this._forceReadonly = typeof forceReadonly === 'function' ? forceReadonly : () => forceReadonly;
    this.immediateBindingUpdate = immediateBindingUpdate;
  }

  private _forceReadonly: () => boolean;

  get forceReadonly(): boolean {
    return this._forceReadonly();
  }

  get dataBinding(): DataBinding {
    return this.dataTracker.binder;
  }

  id(name: string): string {
    return `${this.dataTracker.binder.internalId}-${name}`;
  }

  public buttonPrimary(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'primary' });
  }

  public buttonDefault(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'default' });
  }

  public buttonDanger(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'danger' });
  }

  public buttonWarning(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'warning' });
  }

  public buttonSuccess(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'success' });
  }

  public text(fieldName: string, title?: string, maxLength?: number, events?: InputEventMap): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'text', title, maxLength, events })
      : this.formInput({ fieldName, type: 'text', title, maxLength, events });
  }

  public text1(
    fieldName: string,
    title?: string,
    options?: {
      maxLength?: number;
      toolTip?: string;
      min?: number;
      max?: number;
      placeholder?: string;
      events?: InputEventMap;
      autocaptitalize?: boolean;
      uppercase?: boolean;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, title, type: 'text', ...options })
      : this.formInput({ fieldName, title, type: 'text', ...options });
  }

  public textRequired(fieldName: string, title?: string, maxLength?: number, events?: InputEventMap): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'text', title, maxLength, events })
      : this.formInputRequired({ fieldName, type: 'text', title, maxLength, events });
  }

  public textHidden(fieldName: string, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'hidden', title })
      : this.formInput({ fieldName, type: 'hidden', title });
  }

  public textReadonly(fieldName: string, title?: string, toolTip?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'text', title, toolTip });
  }

  public checkbox(fieldName: string, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'checkbox', title })
      : this.formInput({ fieldName, type: 'checkbox', title });
  }

  public switch(
    fieldName: string,
    title?: string,
    options?: {
      readonly?: boolean;
      class?: string;
      labelSuffix?: string;
    }
  ): TemplateResult {
    return this.forceReadonly || options?.readonly
      ? this.formInputReadOnly({ fieldName, type: 'switch', title, ...options })
      : this.formInput({ fieldName, type: 'switch', title, ...options });
  }

  public switchReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'switch', title });
  }

  public switchRequired(fieldName: string, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'switch', title })
      : this.formInputRequired({ fieldName, type: 'switch', title });
  }

  public radioGroup<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string,
    hideZeroValue?: boolean
  ): TemplateResult {
    let hideValues: number[] | undefined;

    if (hideZeroValue) hideValues = [0];

    return this.forceReadonly
      ? this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptions(options, hideValues), title, true)
      : this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptions(options, hideValues), title);
  }

  public radioGroupHideValues<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string,
    hideValues?: number[],
    map?: { [key: string]: string }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptions(options, hideValues, map), title, true)
      : this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptions(options, hideValues, map), title);
  }

  public note(
    fieldName: string,
    title?: string,
    maxLength?: number,
    toolTip?: string,
    placeholder?: string
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'area', title, maxLength })
      : this.formInput({ fieldName, type: 'area', title, maxLength, toolTip, placeholder });
  }

  public noteRequired(fieldName: string, title?: string, maxLength?: number): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'area', title, maxLength })
      : this.formInputRequired({ fieldName, type: 'area', title, maxLength });
  }

  public noteReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'area', title });
  }

  public date(
    fieldName: string,
    title: string | undefined,
    type: 'date' | 'datetime-local' = 'datetime-local',
    toolTip?: string,
    min?: number
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type, title, toolTip, min })
      : this.formInput({ fieldName, type, title, toolTip, min });
  }

  public dateRequired(
    fieldName: string,
    title: string | undefined,
    type: 'date' | 'datetime-local' = 'datetime-local',
    toolTip?: string,
    min?: number
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type, title, toolTip, min })
      : this.formInputRequired({ fieldName, type, title, toolTip, min });
  }

  public dateReadonly(
    fieldName: string,
    title: string | undefined,
    type: 'date' | 'datetime-local' = 'datetime-local',
    toolTip?: string,
    min?: number
  ): TemplateResult {
    return this.formInputReadOnly({ fieldName, type, title, toolTip, min });
  }

  public int(fieldName: string, title?: string, min?: number, max?: number): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, min, max })
      : this.formInput({ fieldName, type: 'number', title, min, max });
  }

  public intRequired(
    fieldName: string,
    title?: string,
    options?: {
      min?: number;
      max?: number;
      hidden?: boolean;
      events?: InputEventMap;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, ...options })
      : this.formInputRequired({ fieldName, type: 'number', title, ...options });
  }

  public intReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'number', title });
  }

  public float(
    fieldName: string,
    title?: string,
    options?: {
      min?: number;
      max?: number;
      events?: InputEventMap;
    }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, ...options })
      : this.formInput({ fieldName, type: 'number', title, ...options });
  }

  public floatRequired(fieldName: string, title?: string, min?: number, max?: number): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'number', title, min, max })
      : this.formInputRequired({ fieldName, type: 'number', title, min, max });
  }

  public floatReadonly(fieldName: string, title?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'number', title });
  }

  public money(fieldName: string, title?: string, options?: { events?: InputEventMap }): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({
          fieldName,
          type: 'money',
          title,
          ...options
        })
      : this.formInput({ fieldName, type: 'money', title, ...options });
  }

  public moneyRequired(fieldName: string, title?: string, options?: { events?: InputEventMap }): TemplateResult {
    return this.forceReadonly
      ? this.formInputReadOnly({ fieldName, type: 'money', title, ...options })
      : this.formInputRequired({ fieldName, type: 'money', title, ...options });
  }

  public moneyReadonly(fieldName: string, title?: string, toolTip?: string): TemplateResult {
    return this.formInputReadOnly({ fieldName, type: 'money', title, toolTip });
  }

  public enumPicker<EnumeratedType>(fieldName: string, values: EnumeratedType, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(values, undefined), title)
      : this.formSelect(fieldName, ConvertEnumToFormSelectOptions(values, undefined), { title: title });
  }

  public enumPickerReadonly<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string
  ): TemplateResult {
    return this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, undefined), title);
  }

  public enumFilter<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string): TemplateResult {
    return this.forceReadonly
      ? this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, [0]), title)
      : this.formSelect(fieldName, ConvertEnumToFormSelectOptions(options, [0]), { title });
  }

  public enumFilterReadonly<EnumeratedType>(
    fieldName: string,
    options: EnumeratedType,
    title?: string
  ): TemplateResult {
    return this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, [0]), title);
  }

  public arraySelect<ItemType>(
    fieldName: string,
    items: ItemType[],
    convert: (x: ItemType) => FormInputSelectValue,
    options?: { title?: string; events?: InputEventMap; placeholder?: string }
  ): TemplateResult {
    return this.forceReadonly
      ? this.formSelectReadonly(fieldName, MapArrayToFormInputSelectValue(items, convert), options?.title)
      : this.formSelect(fieldName, MapArrayToFormInputSelectValue(items, convert), { ...options });
  }

  public arraySelectReadonly<ItemType>(
    fieldName: string,
    items: ItemType[],
    convert: (x: ItemType) => FormInputSelectValue,
    title?: string
  ): TemplateResult {
    return this.formSelectReadonly(fieldName, MapArrayToFormInputSelectValue(items, convert), title);
  }

  public imagePicker(fieldName: string, title?: string) {
    const changeEvent = () => {
      if (this.immediateBindingUpdate) {
        this.dataTracker.getBinder(fieldName)?.applyChangeToValue();
      }
    };

    return html` <bs-form-image-upload
      data-id=${this.dataBinding.field(fieldName)}
      .value="${this.dataTracker.getObjectValue(fieldName)}"
      data-label=${title}
      @webmodule-change=${changeEvent}
    ></bs-form-image-upload>`;
  }

  /**
   *
   * @param fieldName fieldname of the object to read and write data to
   * @param display
   * @param clickEvent
   * @param title optional title
   * @param slotted add extra buttons into the slots
   * @returns
   */

  public picker(
    fieldName: string,
    display: string,
    clickEvent: EventPickerResult,
    title?: string,
    slotted?: TemplateResult
  ) {
    return this.forceReadonly
      ? this.formPicker(fieldName, display, clickEvent, title, false, true, slotted)
      : this.formPicker(fieldName, display, clickEvent, title, false, false, slotted);
  }

  public pickerRequired(fieldName: string, display: string, clickEvent: EventPickerResult, title?: string) {
    return this.forceReadonly
      ? this.formPicker(fieldName, display, clickEvent, title, true, true)
      : this.formPicker(fieldName, display, clickEvent, title, true);
  }

  public pickerReadonly(fieldName: string, display: string, title?: string) {
    return this.formPicker(
      fieldName,
      display,
      () => {
        return undefined;
      },
      title,
      false,
      true
    );
  }

  getClasses(fieldName: string) {
    return this.classes
      .filter(x => x.id === '*' || x.id == fieldName)
      .map(x => x.classes)
      .join(' ');
  }

  public updatePicker(fieldName: string, newValue: PickerResult) {
    const displayFieldName = this.dataBinding.field(`${fieldName}-displaycontainer`);
    this.dataBinding.setValue(fieldName, newValue.id);
    const elem = this.dataBinding.parent.querySelector('#' + displayFieldName) as unknown as WebmoduleInput;
    if (elem) elem.value = newValue.value;
  }

  protected buttonInternal(caption: string | TemplateResult, options?: ButtonOptionsInternal) {
    return html` <webmodule-button
      ?disabled=${options?.readonly ?? false}
      .variant=${options?.variant ?? 'default'}
      @click=${options?.events?.click}
      slot=${options?.slot ?? nothing}
      size=${options?.size ?? nothing}
      class=${options?.class ?? nothing}
      >${caption}
    </webmodule-button>`;
  }

  private formInputRequired(options: {
    fieldName: string;
    type?: InputType;
    title?: string;
    maxLength?: number;
    decimalPlaces?: number;
    toolTip?: string;
    min?: number;
    max?: number;
    hidden?: boolean;
    events?: InputEventMap;
  }): TemplateResult {
    options.type = options.hidden ? 'hidden' : (options.type ?? 'text');
    options.type = options.type ?? 'text';
    return formInput({
      dataBinding: this.dataBinding,
      dataTracker: this.dataTracker,
      readOnly: false,
      ...options,
      immediateBindingUpdate: this.immediateBindingUpdate,
      required: true
    });
  }

  private formInputReadOnly(options: {
    fieldName: string;
    type?: InputType;
    title?: string | undefined;
    maxLength?: number;
    decimalPlaces?: number;
    toolTip?: string;
    min?: number;
    max?: number;
    class?: string;
    hidden?: boolean;
    events?: InputEventMap;
  }): TemplateResult {
    options.type = options.hidden ? 'hidden' : (options.type ?? 'text');

    return formInput({
      ...options,
      readOnly: true,
      required: false,
      dataBinding: this.dataBinding,
      dataTracker: this.dataTracker
    });
  }

  private formInput(options: {
    fieldName: string;
    type?: InputType;
    title?: string;
    maxLength?: number;
    toolTip?: string;
    min?: number;
    max?: number;
    placeholder?: string;
    events?: InputEventMap;
    autocaptitalize?: boolean;
    labelSuffix?: string;
    class?: string;
  }): TemplateResult {
    const classes = this.getClasses(options.fieldName);
    options.class = `${classes ?? ''} ${options.class ?? ''}`.trim();
    if (isEmptyOrSpace(options.class)) {
      options.class = undefined;
    }
    //
    return formInput({
      dataBinding: this.dataBinding,
      dataTracker: this.dataTracker,
      readOnly: false,
      required: false,
      ...options,
      immediateBindingUpdate: this.immediateBindingUpdate
    });
  }

  private formRadioGroup(fieldName: string, values: string, title?: string, readOnly = false, required = false) {
    const classes = this.getClasses(fieldName);
    return formRadioGroup(fieldName, title, values, this.dataBinding, this.dataTracker, readOnly, required, classes);
  }

  private formSelect(
    fieldName: string,
    values: string | FormInputSelectValue[],
    options: {
      title?: string;
      readOnly?: boolean;
      required?: boolean;
      placeholder?: string;
      events?: InputEventMap;
    }
  ) {
    return formSelect(
      fieldName,
      options?.placeholder ?? '',
      options.title,
      values,
      this.dataBinding,
      this.dataTracker,
      options?.readOnly,
      options.required,
      options.events?.change,
      this.immediateBindingUpdate
    );
  }

  private formPicker(
    fieldName: string,
    display: string,
    clickEvent: EventPickerResult,
    title?: string,
    required = false,
    readonly = false,
    slotted?: TemplateResult
  ) {
    const displayFieldName = this.dataBinding.field(`${fieldName}-displaycontainer`);
    const clPick = async (e: Event) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const newValue = await clickEvent();
      if (newValue) {
        this.updatePicker(fieldName, newValue);
      }
    };
    const label = title ? `${title}:` : nothing;
    const classes = `webmodule-control-left-label  ${this.getClasses(fieldName)}`;
    return html`
      ${this.textHidden(fieldName)}
      <webmodule-input
        id=${displayFieldName}
        class=${classes}
        type="text"
        label=${label}
        ?required=${required}
        ?readonly=${true}
        ?filled=${readonly}
        value=${display}
        size="small"
      >
        ${slotted}
        <webmodule-icon @click=${clPick} class="ms-2" slot="suffix" name="fas-ellipsis" library="fa"></webmodule-icon>
      </webmodule-input>
    `;
  }

  private formSelectReadonly(fieldName: string, options: string | FormInputSelectValue[], title?: string) {
    return formSelect(fieldName, '', title, options, this.dataBinding, this.dataTracker, true);
  }
}
