import { strToMoney } from '../../currency-formatter';
import type { WebmoduleInputMoney } from '../../../components/src/webmodule-components';
import { WebmoduleCheckbox, WebmoduleRadio } from '../../../components/src/webmodule-components';
import WebmoduleToggle from '../../../components/src/components/toggle/toggle';
import { fileToBase64 } from '../../blob-converters';
import { VirtualFile } from '../../../../scriptsSupplier/settings/data/image-upload';

let internalIdSequencer = 100;

export type EventDataBindingFieldName = (input: string, internalId: string) => string;

export function getInternalId() {
  return (internalIdSequencer++).toString();
}

/**
 * Databinding is a means of reading and writing data into html elements
 */
export class DataBinding {
  parent: HTMLElement;
  internalId: string;
  allowMissingElements = false;
  fieldGenerator?: EventDataBindingFieldName;

  constructor(parent: HTMLElement, internalId?: string | null, fieldGenerator?: EventDataBindingFieldName) {
    this.parent = parent;
    this.fieldGenerator = fieldGenerator;
    this.internalId = internalId ?? getInternalId();
  }

  public getElement(fieldName: string): Element | null {
    const internalFieldName = this.field(fieldName);
    return this.parent.querySelector(`#${internalFieldName}`);
  }

  public field(fieldName: string): string {
    fieldName = fieldName.trim();
    return this.fieldGenerator ? this.fieldGenerator(fieldName, this.internalId) : `${fieldName}-${this.internalId}`;
  }

  public readonly(fieldName: string): boolean {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);

    if (!element) {
      element = this.parent.querySelector(`[name="${internalFieldName}"]`);

      if (!element) {
        if (this.allowMissingElements) return true;
        throw new Error(`#${internalFieldName} not found`);
      }
    }
    return element['readonly'] || element['disabled'];
  }

  public exists(fieldName): boolean {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);

    if (!element) {
      element = this.parent.querySelector(`input[name="${internalFieldName}"]:checked`);

      if (!element) {
        return false;
      }
    }
    return true;
  }

  public getValue(fieldName: string): string {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);

    if (!element) {
      element = this.parent.querySelector(`[name="${internalFieldName}"]`);

      if (!element) {
        if (this.allowMissingElements) return '';
        throw new Error(`#${internalFieldName} not found`);
      }
    }

    let value;

    if (element instanceof WebmoduleCheckbox || element instanceof WebmoduleToggle) value = element.checked.toString();
    else value = element['value'] ?? '';

    return value.trimEnd();
  }

  public getInt(fieldName: string, allowNull?: boolean, float?: boolean): number | null {
    const strValue = this.getValue(fieldName).trim();
    if (!strValue || (strValue === '' && allowNull)) return null;

    const value = float ? parseFloat(strValue) : parseInt(strValue);
    const internalFieldName = this.field(fieldName);
    if (isNaN(value)) throw new Error(`#${internalFieldName} is not a number "${strValue}"`);
    return value;
  }

  public getFloat(fieldName: string, allowNull?: boolean): number | null {
    return this.getInt(fieldName, allowNull, true);
  }

  public getMoney(fieldName: string, allowNull?: boolean): number | null {
    const internalFieldName = this.field(fieldName);
    const element = this.parent.querySelector(`#${internalFieldName}`) as any;
    const moneyElement = $(element).closest('webmodule-input-money')[0] as WebmoduleInputMoney;
    const moneyVal = strToMoney(moneyElement?.value ?? '0', 4);
    if (!isNaN(moneyVal)) return moneyVal;
    else if (allowNull) return null;
    else return 0;
  }

  public getBoolean(fieldName: string): boolean {
    const strValue = this.getValue(fieldName);
    return strValue === 'true';
  }

  //TODO > This needs to be removed: GetValue should return the value
  /**
   * Gets the contents of a file that has been uploaded to an element with the given field name.
   * @param fieldName The field name of the element.
   * @returns The contents of the file, undefined (unable to get contents) or null (no file uploaded).
   */
  public getFiles(fieldName: string): FileList | null {
    const internalFieldName = this.field(fieldName);
    const element = this.parent.querySelector(`#${internalFieldName}`);
    if (!element || !(element instanceof HTMLInputElement)) throw new Error(`#${internalFieldName} not found`);

    return element.files;
  }

  public async createVirtualFile(file: File | null | undefined): Promise<VirtualFile | null> {
    if (!file) return null;
    const fileNameParts = file.name.split('.');
    const fileName = fileNameParts.at(0) ?? '';
    const extension = `.${fileNameParts.pop()}` ?? '';
    const content = this.getContentAsString(await fileToBase64(file)) ?? '';
    // file content at this point should be a base64 encoded string after the content metadata with a comma as the delimiter
    const separatorIndex = content.indexOf(',');
    // get everything from beyond the comma. If there is no comma (index -1) this will return the whole string
    const fileContent = content.substring(separatorIndex + 1);
    return {
      name: fileName,
      extension: extension,
      content: fileContent,
      type: file.type,
      filePath: file.webkitRelativePath
    };
  }

  public getFile(fieldName: string, fileIndex: number): File | null {
    const files = this.getFiles(fieldName);
    if (files) {
      return files.item(fileIndex);
    }
    return null;
  }

  //TODO> This needs to be removed. Set Value should clear the value
  public removeFiles(fieldName: string): void {
    const internalFieldName = this.field(fieldName);
    const element = this.parent.querySelector(`#${internalFieldName}`) as HTMLInputElement;
    if (!element) throw new Error(`#${internalFieldName} not found`);
    // make sure that the element has files before clearing, otherwise we could clear something else by mistake.
    if (element.files) {
      element.value = '';
    }
  }

  public setValue(fieldName: string, value: string | null) {
    const internalFieldName = this.field(fieldName);
    let element = this.parent.querySelector(`#${internalFieldName}`);

    if (!element) {
      element = this.parent.querySelector(`[name="${internalFieldName}"][value="${value}"]`);

      if (!element) {
        if (this.allowMissingElements) return;
        throw new Error(`#${internalFieldName} not found`);
      }
    }

    if (element instanceof WebmoduleCheckbox || element instanceof WebmoduleToggle) element.checked = value === 'true';
    else if (element instanceof WebmoduleRadio) element.checked = true;
    else element['value'] = value;
  }

  /**
   * Private helper function which takes the string output from fileToBase64 and returns it either as a string or null, converting the ArrayBuffer to a string if required.
   * @param content
   * @returns
   */
  private getContentAsString(content: string | ArrayBuffer | null): string | null {
    if (content) {
      // check to see if the content is already a string
      if (typeof content === 'string') {
        return content;
      }
      // if we get here, then we're dealing with an arraybuffer, so we'll need to read and decode it
      if (content instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(content);
      }
      // ideally we shouldn't get here
      throw new Error(`content isn't a string or ArrayBuffer, but is instead ${content}`);
    }
    return null;
  }
}
